import React from 'react';
import '../styles/Ask.css';
import DialogGeneric from '../generic components/DialogGeneric';
import { useSession } from '../contexts/SessionContext';
import { webSocketClient } from '../ws/WebSocketClient';
import { MessageData } from './chatBox/Message';
import { v4 as uuidv4 } from 'uuid';

interface AskConfigItem {
  description: string;
  label: string;
  required: boolean;
  type: string;
}

interface AskConfig {
  [key: string]: AskConfigItem;
}

export interface AskProps {
  handleClick?: (show: boolean) => void;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  questionId: string;
  question: string;
  agent: string;
  handleMessage: (message: MessageData) => void; // Add handleMessage prop
}

function Ask(props: AskProps) {
  const [open, setOpen] = React.useState(true);
  const [validationErrors, setValidationErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [formData, setFormData] = React.useState<{ answer?: string }>({});
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { sessionId } = useSession();

  const handleClose = () => {
    setOpen(false);
    props.handleClick!(false);
    props.setShow!(false);
  };

  const displayName = `${props.agent} asks: ${props.question}`;
  const AskData: AskConfig = {
    answer: {
      description: 'Your answer to the question',
      label: 'Answer',
      required: true,
      type: 'string',
    },
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitLoading(true);

    const errors: { [key: string]: string } = {};
    Object.keys(AskData).forEach((key: string) => {
      const property: AskConfigItem = AskData[key];
      if (property.required && !formData[key as keyof typeof formData]) {
        errors[key] = `${property.label} is required.`;
      }
    });

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      setSubmitLoading(false);
      return;
    }

    try {
      const answer = formData['answer'] as string;
      if (sessionId && answer.trim()) {
        // Send the answer to the server
        webSocketClient.sendReply(sessionId, props.questionId, answer);
      }

      // Use the handleMessage function to add the answer to the chat
      props.handleMessage({
        id: uuidv4(),
        sender: 'You',
        message: answer,
      });

      setOpen(false);
      props.handleClick!(false);
    } catch (error) {
      setSubmitLoading(false);
      console.log(`Error submitting answer: ${error}`);
    }
  };

  const handleUpdate = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: '' });
  };

  return (
    <div data-testid="Ask-id">
      <DialogGeneric
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        loading={false}
        errorPost={false}
        open={open}
        displayName={displayName}
        dialogFields={AskData}
        submitLoading={submitLoading}
        validationErrors={validationErrors}
      />
    </div>
  );
}

export default Ask;
