import React, { createContext, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MessageData } from '../components/chatBox/Message'; // Assuming MessageData is defined here

// Define the context type
interface MessageContextType {
  messages: MessageData[];
  addMessage: (message: string, sender?: string, id?: string) => void;
}

// Create the context
const MessageContext = createContext<MessageContextType | undefined>(undefined);

// Message provider component
interface MessageProviderProps {
  children: React.ReactNode;
}

export const MessageProvider: React.FC<MessageProviderProps> = ({
  children,
}) => {
  const [messages, setMessages] = useState<MessageData[]>([]);

  // Function to add a new message
  const addMessage = (message: string, sender?: string, id?: string) => {
    if (!sender) {
      sender = 'You';
    }
    if (!id) {
      id = uuidv4();
    }
    const newMessage: MessageData = {
      id: id,
      sender,
      message,
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  return (
    <MessageContext.Provider value={{ messages, addMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook to use the message context
export const useMessages = (): MessageContextType => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return context;
};
