import { BASE_URL } from '../config/config';

export interface ApiRequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any> | null;
}

export const apiRequest = async <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any> | null = null,
  apiKey: string,
  sessionId: string
): Promise<T> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'X-API-Key': apiKey,
    'X-Session-ID': sessionId,
  };

  const options: RequestInit = {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) }),
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, options);
  if (!response.ok) {
    throw new Error(`Error (HTTP ${response.status}): ${response.statusText}`);
  }

  return response.json() as Promise<T>;
};

export const handleApiRequest = async <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: Record<string, any> | null = null,
  apiKey: string | null,
  sessionId: string | null
): Promise<T | undefined> => {
  if (!apiKey || !sessionId) {
    console.warn('API key or Session ID missing');
    return undefined;
  }
  try {
    return await apiRequest<T>(endpoint, method, body, apiKey, sessionId);
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export const fetchFileContent = async (
  endpoint: string,
  apiKey: string,
  sessionId: string
): Promise<string> => {
  const headers: HeadersInit = {
    'X-API-Key': apiKey,
    'X-Session-ID': sessionId,
  };

  const options: RequestInit = {
    method: 'GET',
    headers,
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, options);
  if (!response.ok) {
    throw new Error(`Error (HTTP ${response.status}): ${response.statusText}`);
  }

  return response.text(); // Fetches the content as plain text instead of JSON
};

export const handleFileRequest = async (
  endpoint: string,
  apiKey: string | null,
  sessionId: string | null
): Promise<string | undefined> => {
  if (!apiKey || !sessionId) {
    console.warn('API key or Session ID missing');
    return undefined;
  }
  try {
    return await fetchFileContent(endpoint, apiKey, sessionId);
  } catch (error) {
    console.error('File fetch failed:', error);
    throw error;
  }
};
