import { useEffect } from 'react';
import { useMessages } from '../contexts/MessageContext';
import { webSocketClient } from '../ws/WebSocketClient'; // Correctly import the webSocketClient
import { MessageData } from '../components/chatBox/Message'; // Use the existing MessageData type

export const useMessageController = () => {
  const { addMessage } = useMessages();

  useEffect(() => {
    // Handle incoming chat messages
    const handleIncomingMessage = (data: MessageData) => {
      // Ensure we pass all necessary fields to the addMessage function
      addMessage(data.message, data.sender, data.id);
    };

    // Subscribe to WebSocket events for chat messages only
    webSocketClient.subscribeToMessages(handleIncomingMessage);
    webSocketClient.subscribeToQuestions(handleIncomingMessage);

    // Cleanup subscriptions on unmount
    return () => {
      webSocketClient.unsubscribeFromMessages(handleIncomingMessage);
      webSocketClient.unsubscribeFromQuestions(handleIncomingMessage);
    };
  }, [addMessage]);
};

export default useMessageController;
