import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';

interface Shortcut {
  keys: string[]; // Shortcut keys, e.g., ['Ctrl', 'S']
  action: () => void; // Function to execute on shortcut
}

interface ShortcutContextType {
  registerShortcut: (shortcut: Shortcut) => void;
  unregisterShortcut: (keys: string[]) => void;
}

const ShortcutContext = createContext<ShortcutContextType | null>(null);

export const ShortcutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const shortcuts = React.useRef<Map<string, Shortcut>>(new Map());

  const registerShortcut = useCallback((shortcut: Shortcut) => {
    const keyString = shortcut.keys.join('+').toLowerCase();
    shortcuts.current.set(keyString, shortcut);
  }, []);

  const unregisterShortcut = useCallback((keys: string[]) => {
    const keyString = keys.join('+').toLowerCase();
    shortcuts.current.delete(keyString);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const pressedKeys = [
      event.ctrlKey && 'ctrl',
      event.shiftKey && 'shift',
      event.altKey && 'alt',
      event.metaKey && 'meta', // For Mac Command key
      event.key.toLowerCase(),
    ].filter(Boolean);

    const keyString = pressedKeys.join('+');
    const shortcut = shortcuts.current.get(keyString);
    if (shortcut) {
      event.preventDefault();
      shortcut.action();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <ShortcutContext.Provider value={{ registerShortcut, unregisterShortcut }}>
      {children}
    </ShortcutContext.Provider>
  );
};

export const useShortcutContext = () => {
  const context = useContext(ShortcutContext);
  if (!context) {
    throw new Error(
      'useShortcutContext must be used within a ShortcutProvider'
    );
  }
  return context;
};
