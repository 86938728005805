import { Grid, Paper, Stack, ListItem, Box } from '@mui/material';
import Sidebar from './Sidebar';
import SideFolderIcon from '../assets/SideFolderIcon.svg';
import codeBranch from '../assets/codeBranch.svg';
import fi from '../assets/fi.svg';
import github from '../assets/github.png';
import AICommands from './middleComponent/aiCommands/AICommands';
import ChatBox from './chatBox/ChatBox';
import '../styles/Main.css';
// import '../helpers/patchForGoogleAutofill';

const Main = () => {
  return (
    <div data-testid="main-component" className="main-container">
      {/* Sidebar icons stack */}
      <Stack
        direction="column"
        style={{
          width: '45px',
          height: 'auto',
        }}
      >
        <Box sx={{ marginTop: '25px', height: '100%', overflow: 'hidden' }}>
          <ListItem sx={{ justifyContent: 'center' }}>
            <img src={SideFolderIcon} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <img src={codeBranch} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <img src={fi} alt="folder" />
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <img src={github} alt="folder" />
          </ListItem>
        </Box>
      </Stack>

      {/* Main grid layout */}
      <Grid container spacing={0}>
        {/* Sidebar section */}
        <Grid item xs={12} md={3}>
          <Sidebar />
        </Grid>

        {/* AI commands section */}
        <Grid item xs={12} md={6}>
          <AICommands />
        </Grid>

        {/* ChatBox section */}
        <Grid item xs={12} md={3}>
          <Paper className="chatbox-paper">
            <Box className="chatbox-container">
              <ChatBox />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
