import React, { useState } from 'react';
import { useMessages } from '../../contexts/MessageContext'; // Adjust import path
import './ChatBox.css';
import { useSession } from '../../contexts/SessionContext';
import { webSocketClient } from '../../ws/WebSocketClient';

const MessageInput: React.FC = () => {
  const { apiKey, sessionId } = useSession(); // Get apiKey and sessionId from the context
  const { addMessage } = useMessages(); // Get addMessage from the context
  const [input, setInput] = useState(''); // Manage local state for the input field

  // Handle input field change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value); // Update local input state
  };

  // Send the message using addMessage from MessageContext
  const handleSendMessage = () => {
    if (input.trim()) {
      addMessage(input, 'You'); // Call addMessage with input and sender
      if (sessionId) {
        webSocketClient.sendMessage(sessionId, input);
      } else {
        console.warn('No session ID available. Message not sent.');
      }
      setInput(''); // Clear the input field after sending
    }
  };

  // Trigger sending on Enter key press
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSendMessage(); // Send the message when Enter is pressed
    }
  };

  return (
    <div className="message-input">
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Interact with Epicenter or send a command"
      />
      <button onClick={handleSendMessage} aria-label="Send Message">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="currentColor"
        >
          <path d="M2 21l21-9L2 3v7l15 2-15 2z" />
        </svg>
      </button>
    </div>
  );
};

export default MessageInput;
