import React, { createContext, useContext, useEffect, useState } from 'react';
import { webSocketClient } from '../ws/WebSocketClient';
import { SystemMessage } from '../ws/messages/SystemMessage';

interface ActivityContextProps {
  active: boolean;
}

const ActivityContext = createContext<ActivityContextProps | undefined>(
  undefined
);

export const ActivityProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const handleSystemMessage = (message: SystemMessage) => {
      if (message.type === 'activity' && message.metadata?.state) {
        const isActive = message.metadata.state === 'begin';
        setActive(isActive);
      }
    };

    webSocketClient.subscribeToSystemMessages(handleSystemMessage);

    return () => {
      webSocketClient.unsubscribeFromSystemMessages(handleSystemMessage);
    };
  }, []);

  return (
    <ActivityContext.Provider value={{ active }}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = (): ActivityContextProps => {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivity must be used within an ActivityProvider');
  }
  return context;
};
