import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { BASE_URL } from '../config/config';
import { useSession } from '../contexts/SessionContext';
import { useProject } from './ProjectContext';
import { Verb } from '../models/VerbDescriptor';

interface VerbContextProps {
  loading: boolean;
  verbs: Verb[];
  featuredVerbs: Verb[];
  allVerbs: Verb[]; // Exposing allVerbs directly in the context
  getVerbByName: (name: string) => Verb | undefined;
}

const VerbContext = createContext<VerbContextProps | undefined>(undefined);

// Constants for fetch intervals
const FETCH_INTERVAL_MS = 10 * 60 * 1000; // 10 minutes in milliseconds
const RETRY_INTERVAL_MS = 60 * 1000; // 1 minute in milliseconds for retry

export const VerbProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSession();
  const { projectId } = useProject();
  const [loading, setLoading] = useState(false);
  const allVerbs = useRef<Verb[]>([]); // Use ref for allVerbs to avoid unnecessary re-renders
  const [retry, setRetry] = useState(false);

  const getVerbByName = (name: string): Verb | undefined => {
    return allVerbs.current.find((verb) => verb.name === name);
  };

  // Fetch verbs metadata with retry logic
  const dialogFieldsMetadata = useCallback(async () => {
    setLoading(true);
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'X-API-Key': session.apiKey || '',
      'X-Session-ID': session.sessionId || '',
    };

    const url = `${BASE_URL}/verb/all`; // Fetching all verbs

    try {
      const response = await fetch(url, { headers });
      if (response.status === 200) {
        const data = (await response.json()) as Verb[];
        allVerbs.current = data; // Update the ref's current value without re-rendering
        setRetry(false); // Reset retry state on successful load
      } else {
        console.warn(`Failed to fetch verbs: Status ${response.status}`);
        setRetry(true); // Set retry to trigger frequent retry
      }
    } catch (error) {
      console.error('Error fetching verb metadata:', error);
      setRetry(true); // Retry on error
    } finally {
      setLoading(false);
    }
  }, [session.apiKey, session.sessionId]); // Memoize based on session dependencies

  useEffect(() => {
    // Initial fetch on component mount
    dialogFieldsMetadata();

    // Interval for periodic fetching
    const interval = setInterval(
      () => {
        dialogFieldsMetadata();
      },
      retry ? RETRY_INTERVAL_MS : FETCH_INTERVAL_MS
    );

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [retry, dialogFieldsMetadata]); // Only depend on retry and dialogFieldsMetadata

  // Filter verbs based on project context
  const verbs = projectId
    ? allVerbs.current // In project context, return all verbs
    : allVerbs.current.filter((verb) => !verb.projectRequired); // Outside project context, exclude verbs requiring a project

  const featuredVerbs = verbs.filter((verb) => verb.featured);

  return (
    <VerbContext.Provider
      value={{
        loading,
        verbs,
        featuredVerbs,
        allVerbs: allVerbs.current, // Exposing allVerbs in the context value
        getVerbByName,
      }}
    >
      {children}
    </VerbContext.Provider>
  );
};

// Custom hook to use the VerbContext
export const useVerb = () => {
  const context = useContext(VerbContext);
  if (!context) {
    throw new Error('useVerb must be used within a VerbProvider');
  }
  return context;
};
