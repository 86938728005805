(window as any).MonacoEnvironment = {
  getWorkerUrl: function (moduleId: string, label: string) {
    return `data:text/javascript;charset=utf-8,
        self.MonacoEnvironment = {
          baseUrl: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.29.0/min/'
        };
        importScripts('https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.29.0/min/vs/base/worker/workerMain.min.js');
      `;
  },
};
export {};
