import React, { useEffect, useRef } from 'react';
import { useActivity } from '../contexts/ActivityContext';

const MIN_DELAY = 800; // How long an activity needs to be, in milliseconds, befure we play a done sound.

const ActivitySoundPlayer: React.FC = () => {
  const { active } = useActivity();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const doneAudioRef = useRef<HTMLAudioElement | null>(null);
  const lastActiveTime = useRef<number | null>(null); // Track when active was last true so we only play done sound if active was true for a certain time

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/sounds/brain-active.mp3');
      audioRef.current.loop = true;
    }
    if (!doneAudioRef.current) {
      doneAudioRef.current = new Audio('/sounds/done-sound.mp3');
    }

    if (active) {
      // Record the start time when `active` becomes true
      lastActiveTime.current = Date.now();
      audioRef.current
        .play()
        .catch((error) => console.error('Active sound play failed:', error));
    } else {
      // Pause active sound
      audioRef.current.pause();
      audioRef.current.currentTime = 0;

      // Calculate how long `active` was true
      const activeDuration = Date.now() - (lastActiveTime.current || 0);
      if (lastActiveTime.current !== null && activeDuration >= MIN_DELAY) {
        // Start from a specific time in the done sound if desired
        doneAudioRef.current.currentTime = 0.5; // Example: start from 0.5 seconds
        doneAudioRef.current
          .play()
          .catch((error) => console.error('Done sound play failed:', error));
      }
    }

    // Cleanup on unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [active]);

  return null; // Invisible component managing sounds
};

export default ActivitySoundPlayer;
