// AuthContext.tsx

import React, { createContext, useContext, ReactNode } from 'react';

interface AuthContextProps {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isAuthenticated = () => {
    // Check if the user is authenticated (e.g., by checking sessionStorage)
    const userCredentials = sessionStorage.getItem('userCredentials');
    return !!userCredentials;
  };

  const login = () => {
    // Implement your login logic and set user credentials in sessionStorage
    // For simplicity, let's assume a successful login for demonstration purposes
    sessionStorage.setItem(
      'userCredentials',
      JSON.stringify({ username: 'demoUser' })
    );
  };

  const logout = () => {
    // Implement your logout logic and clear user credentials from sessionStorage
    sessionStorage.removeItem('userCredentials');
  };

  const authContextValue: AuthContextProps = {
    isAuthenticated: isAuthenticated(),
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
