import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSession } from '../contexts/SessionContext';
import { webSocketClient } from '../ws/WebSocketClient';

interface ConnectionContextProps {
  isConnected: boolean;
}

const ConnectionContext = createContext<ConnectionContextProps | null>(null);

export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (context === null) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }
  return context;
};

interface ConnectionProviderProps {
  children: React.ReactNode;
}

export const ConnectionProvider: React.FC<ConnectionProviderProps> = ({
  children,
}) => {
  const { apiKey, sessionId } = useSession();
  const [isConnected, setIsConnected] = useState(webSocketClient.isConnected());

  useEffect(() => {
    if (apiKey && sessionId) {
      webSocketClient.connect(apiKey, sessionId);
    } else {
      webSocketClient.disconnect();
    }

    // Subscribe to connection state changes
    const unsubscribe =
      webSocketClient.subscribeToConnectionChanges(setIsConnected);

    // Cleanup on unmount
    return () => {
      unsubscribe();
      webSocketClient.disconnect();
    };
  }, [apiKey, sessionId]);

  return (
    <ConnectionContext.Provider value={{ isConnected }}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionContext;
