import React from 'react';
import './ConnectionState.css';
import { useConnection } from '../contexts/ConnectionContext';

const ConnectionState: React.FC = () => {
  const { isConnected } = useConnection();

  return (
    <div className="connection_state">
      <span
        className={`dot ${isConnected ? 'connected' : 'disconnected'}`}
      ></span>
      <span>{isConnected ? 'Connected' : 'Disconnected'}</span>
    </div>
  );
};

export default ConnectionState;
