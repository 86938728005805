import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Badge, MenuItem, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Group from '../assets/Group.png';
import { useSession } from '../contexts/SessionContext';
import { useProject } from '../contexts/ProjectContext';
import '../styles/Header.css';
import GenericMenu from '../generic components/MenuGeneric';
import ProjectMenu from '../components/ProjectMenu';
import ProjectSelectionMenu from './ProjectSelectionMenu';
import VerbMenu from '../components/VerbMenu';
import { menuItems } from '../data/MenuListData';

export default function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorProjectMenu, setAnchorProjectMenu] =
    useState<null | HTMLElement>(null);
  const [anchorProjectSelectionMenu, setAnchorProjectSelectionMenu] =
    useState<null | HTMLElement>(null);
  const [verbMenuAnchorEl, setVerbMenuAnchorEl] = useState<null | HTMLElement>(
    null
  ); // VerbMenu state
  const [verbMenuOpen, setVerbMenuOpen] = useState(false);

  const { projectName } = useProject();
  const { login } = useSession();

  const handleVerbMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setVerbMenuAnchorEl(event.currentTarget);
    setVerbMenuOpen((prev) => !prev);
  };

  const handleVerbMenuClose = () => {
    setVerbMenuAnchorEl(null);
    setVerbMenuOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorProjectMenu(null);
    setAnchorProjectSelectionMenu(null);
  };

  // const handleHamburgerClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget as HTMLElement);
  // };

  const handleProjectMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProjectMenu(event.currentTarget as HTMLElement);
  };

  const handleProjectSelectionMenuClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorProjectSelectionMenu(event.currentTarget as HTMLElement);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ background: '#363E56', boxShadow: 'none' }}
      >
        <Toolbar>
          {/* <Button id="basic-button" onClick={handleHamburgerClick}>
            <img
              src={Group}
              alt="Group"
              style={{ height: '14px', width: '21px' }}
            />
          </Button>

          <GenericMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            items={menuItems}
          /> */}

          <Button
            id="project-menu-button"
            onClick={handleProjectMenuClick}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            Files
          </Button>

          <ProjectMenu
            anchorEl={anchorProjectMenu}
            open={Boolean(anchorProjectMenu)}
            onClose={handleClose}
          />

          <Button
            id="verb-menu-button"
            onClick={handleVerbMenuClick}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            Actions
          </Button>

          <VerbMenu
            anchorEl={verbMenuAnchorEl}
            open={verbMenuOpen}
            onClose={handleVerbMenuClose}
          />

          <Button
            id="project-selection-menu-button"
            onClick={handleProjectSelectionMenuClick}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            {projectName || 'Folder Name'}
          </Button>

          <ProjectSelectionMenu
            anchorEl={anchorProjectSelectionMenu}
            open={Boolean(anchorProjectSelectionMenu)}
            onClose={handleClose}
          />

          <Box sx={{ flexGrow: 1 }} />

          <MenuItem>
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon sx={{ color: '#5D647C' }} />
              </Badge>
            </IconButton>
          </MenuItem>

          <Tooltip title="Open Profile">
            <Button sx={{ color: '#ffffff', textTransform: 'none' }}>
              <div className="identity">{login}</div>
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
