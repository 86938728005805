import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from "./App";
import Router from './Routes';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './generic components/AuthUser';
import 'monaco-editor/esm/vs/editor/editor.all.js';
import './Monaco.worker';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
