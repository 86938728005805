import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the session context
interface SessionContextType {
  apiKey: string;
  setApiKey: (key: string) => void;
  sessionId: string | null;
  setSessionId: (sessionId: string) => void;
  login: string;
  username: string;
  email: string;
  updateCredentials: (credentials: {
    apiKey: string;
    sessionId: string;
    login: string;
    username: string;
    email: string;
  }) => void;
}

// Create a context for the session (API key and sessionId)
const SessionContext = createContext<SessionContextType | undefined>(undefined);

// Create a provider component to wrap your entire application
interface SessionProviderProps {
  children: ReactNode;
}

// export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => { // TODO: Rename this class to SessionProvider
export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  console.debug('Rendering SessionProvider');
  // Get the initial API key and sessionId from sessionStorage
  const [apiKey, setApiKey] = useState<string>(
    sessionStorage.getItem('userCredentials')
      ? JSON.parse(sessionStorage.getItem('userCredentials') as string).apiKey
      : ''
  );

  const [login] = useState<string>(
    sessionStorage.getItem('userCredentials')
      ? JSON.parse(sessionStorage.getItem('userCredentials') as string).login
      : ''
  );

  const [username] = useState<string>(
    sessionStorage.getItem('userCredentials')
      ? JSON.parse(sessionStorage.getItem('userCredentials') as string).username
      : ''
  );

  const [email] = useState<string>(
    sessionStorage.getItem('userCredentials')
      ? JSON.parse(sessionStorage.getItem('userCredentials') as string).email
      : ''
  );

  const [sessionId, setSessionIdState] = useState<string | null>(
    sessionStorage.getItem('sessionId')
  );

  // Function to update sessionId and save it to sessionStorage
  const setSessionId = (sessionId: string) => {
    setSessionIdState(sessionId);
    sessionStorage.setItem('sessionId', sessionId);
  };

  // Function to update credentials in the context and sessionStorage
  const updateCredentials = (credentials: {
    apiKey: string;
    sessionId: string;
    login: string;
    username: string;
    email: string;
  }) => {
    setApiKey(credentials.apiKey);
    setSessionId(credentials.sessionId);
    sessionStorage.setItem('userCredentials', JSON.stringify(credentials));
  };

  return (
    <SessionContext.Provider
      value={{
        apiKey,
        setApiKey,
        sessionId,
        setSessionId,
        login,
        username,
        email,
        updateCredentials,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

// Create a custom hook to access the session context
export const useSession = (): SessionContextType => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  return context;
};

// /** @deprecated */
// export const useApiKey = (): string | null => { // LEGACY: Replace all calls with useSession()
//   const { apiKey } = useSession();
//   return apiKey || null;
// }
