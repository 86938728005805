import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRoutesProps {
  children: React.ReactNode;
}

function ProtectedRoutes({ children }: ProtectedRoutesProps) {
  console.debug('Inside ProtectedRoutes');

  if (!sessionStorage.getItem('userCredentials')) {
    return <Navigate to="/" />;
  } else {
    return <div>{children}</div>;
  }
}

export default ProtectedRoutes;
