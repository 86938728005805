import React from 'react';
import { BASE_URL } from '../config/config';
import DialogGeneric from '../generic components/DialogGeneric';
import { useProject } from '../contexts/ProjectContext';
import { useMessages } from '../contexts/MessageContext';
import { useVerb } from '../contexts/VerbContext';
import { useSession } from '../contexts/SessionContext';
import { Verb } from '../models/VerbDescriptor';
import { normalizePath } from '../utils/fileUtils';

interface ProjectConfigItem {
  description: string;
  label: string;
  required: boolean;
  from_context?: boolean;
  type: string;
  display?: boolean;
}

interface DialogBoxProps {
  boxOpen: boolean;
  value: string;
  displayName: string;
  callFromDialog: (boxOpen: boolean) => void;
}

type FormDataMap = Record<string, string | File>;
type ValidationErrors = Record<string, string>;

export default function DialogBox(props: DialogBoxProps) {
  const {
    projectId,
    projectName,
    createProject,
    selectedFilePath,
    setCreateProject,
    newProjectCreated,
    setDirty,
  } = useProject();
  const { verbs, loading: verbsLoading } = useVerb();
  const { addMessage } = useMessages();
  const { apiKey, sessionId } = useSession();

  const [open, setOpen] = React.useState<boolean>(props.boxOpen);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<FormDataMap>({});
  const [errorPost, setErrorPost] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] =
    React.useState<ValidationErrors>({});

  const handleClose = () => {
    setOpen(false);
    props.callFromDialog(false);
    setDirty(true);
    setFormData({});
    setValidationErrors({});
  };

  // Retrieve the verb configuration from context
  const selectedVerb = verbs.find((verb: Verb) => verb.name === props.value);
  const descriptor = selectedVerb?.descriptor || {};

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: '' });
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    const formDataObj: FormDataMap = {};

    const formData = new FormData(
      e ? (e.target as HTMLFormElement) : undefined
    );

    formData.forEach((value, key) => {
      if (value && !(typeof value === 'string' && value.trim() === '')) {
        formDataObj[key] = value;
      }
    });

    console.log('Form Data:', formDataObj);
    console.log('Project ID:', projectId);
    console.log('Project Name:', projectName);
    console.log('Selected File Path from Session:', selectedFilePath);

    // Add project context if applicable
    if (projectId) formDataObj['project_id'] = projectId;
    if (projectName) formDataObj['project_name'] = projectName;
    if (!formData.get('filename') && selectedFilePath) {
      formDataObj['filename'] = normalizePath(selectedFilePath);
    }

    // Validate required fields
    const errors: ValidationErrors = {};
    Object.keys(descriptor).forEach((key: string) => {
      const property: ProjectConfigItem = descriptor[key];
      if (property.required && property.from_context && !formDataObj[key]) {
        errors[key] =
          `${property.label} is required from the context but is missing.`;
      } else if (property.required && !formDataObj[key]) {
        errors[key] = `${property.label} is required.`;
      }
    });

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) return;

    if (formDataObj.prompt) {
      addMessage(formDataObj.prompt as string, 'You');
    }

    setSubmitLoading(true);
    setOpen(false);
    props.callFromDialog(false);

    try {
      const response = await fetch(`${BASE_URL}/verb/${props.value}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey || '',
          'X-Session-ID': sessionId || '',
        },
        body: JSON.stringify(formDataObj),
      });

      if (!response.ok)
        throw new Error(`Error in form submit ${response.statusText}`);
      else {
        const data = await response.json();
        const new_command = data.new_command;
        if (new_command) {
          newProjectCreated(new_command.id);
          setDirty(true);
        }
        if (data.new_project_created) {
          setCreateProject(true); // This is probably going away.
        }
      }
      if (props.value === 'new_project') setCreateProject(!createProject);
    } catch (error) {
      console.error(`Error in Form Submit of ${props.value}:`, error);
      setErrorPost(true);
    } finally {
      setSubmitLoading(false);
      setFormData({});
      setValidationErrors({});
    }

    setTimeout(() => setErrorPost(false), 5000);
  };

  // Trigger handleSubmit if noParams is true on initial load
  React.useEffect(() => {
    if (selectedVerb?.noParams) {
      handleSubmit();
    }
  }, [selectedVerb]);

  return (
    <DialogGeneric
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleUpdate={handleUpdate}
      loading={verbsLoading || submitLoading}
      errorPost={errorPost}
      open={open}
      displayName={props.displayName}
      dialogFields={descriptor}
      value={props.value}
      passToDialog={props.callFromDialog}
      validationErrors={validationErrors}
      projectName={projectId}
      submitLoading={submitLoading}
    />
  );
}
