import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '../config/config';
import { MessageData } from '../components/chatBox/Message';
import { InvalidateFilesystemMessage } from './messages/InvalidateFilesystemMessage';
import { SystemMessage } from './messages/SystemMessage';

const SOCKET_TIMEOUT = 5000;

class WebSocketClient {
  private socket: Socket | null = null;
  private heartbeatInterval: NodeJS.Timeout | null = null;
  private messageCallbacks: ((message: MessageData) => void)[] = [];
  private connectionCallbacks: ((connected: boolean) => void)[] = [];
  private questionCallbacks: ((message: MessageData) => void)[] = [];
  private filesystemCallbacks: ((data: InvalidateFilesystemMessage) => void)[] =
    [];
  private systemCallbacks: ((message: SystemMessage) => void)[] = [];
  private apiKey: string | null = null;
  private sessionId: string | null = null;

  connect(apiKey: string, sessionId: string) {
    if (this.socket?.connected) {
      console.warn('Already connected to WebSocket');
      return;
    }
    this.apiKey = apiKey;
    this.sessionId = sessionId;
    this.socket = io(BASE_URL + '/ws', {
      path: '/socket.io',
    });

    this.setUpListeners();
  }

  disconnect() {
    if (!this.socket) return;
    this.stopHeartbeat();
    this.removeListeners();
    this.socket.disconnect();
  }

  isConnected(): boolean {
    return !!this.socket?.connected;
  }

  private setUpListeners() {
    if (!this.socket) return;

    this.socket.on('connect', () => {
      this.connectionCallbacks.forEach((cb) => cb(true));
      if (this.apiKey && this.sessionId) this.login();
      this.startHeartbeat();
    });

    this.socket.on('disconnect', () => {
      this.connectionCallbacks.forEach((cb) => cb(false));
      this.stopHeartbeat();
    });

    this.socket.on('message', (data) => {
      const type = data.type;
      if (type === 'comment') {
        this.messageCallbacks.forEach((cb) => cb(data));
      } else if (type === 'invalidate_filesystem') {
        this.filesystemCallbacks.forEach((cb) => cb(data));
      } else if (type === 'question') {
        this.questionCallbacks.forEach((cb) => cb(data));
      } else {
        console.warn('Unknown message type received:', type);
      }
    });

    this.socket.on('invalidate_filesystem', (data) => {
      this.filesystemCallbacks.forEach((cb) => cb(data));
    });

    this.socket.on('system', (data) => {
      this.systemCallbacks.forEach((cb) => cb(data));
    });
  }

  private removeListeners() {
    if (!this.socket) return;
    this.socket.off('connect');
    this.socket.off('disconnect');
    this.socket.off('message');
    this.socket.off('invalidate_filesystem');
    this.socket.off('system');
  }

  private startHeartbeat() {
    if (this.heartbeatInterval) return;
    this.heartbeatInterval = setInterval(() => {
      this.sendHeartbeat();
    }, SOCKET_TIMEOUT);
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  private sendHeartbeat() {
    this.socket?.emit('status', { message: 'OK' });
  }

  private login() {
    if (this.socket) {
      this.socket.emit(
        'login',
        { apiKey: this.apiKey, sessionId: this.sessionId },
        (response: any) => {
          console.info('Login response received:', response);
        }
      );
    }
  }

  subscribeToMessages(callback: (message: MessageData) => void) {
    this.messageCallbacks.push(callback);
    return () => {
      this.unsubscribeFromMessages(callback);
    };
  }

  unsubscribeFromMessages(callback: (message: MessageData) => void) {
    this.messageCallbacks = this.messageCallbacks.filter(
      (cb) => cb !== callback
    );
  }

  subscribeToConnectionChanges(callback: (connected: boolean) => void) {
    this.connectionCallbacks.push(callback);
    return () => {
      this.unsubscribeFromConnectionChanges(callback);
    };
  }

  unsubscribeFromConnectionChanges(callback: (connected: boolean) => void) {
    this.connectionCallbacks = this.connectionCallbacks.filter(
      (cb) => cb !== callback
    );
  }

  subscribeToQuestions(callback: (message: MessageData) => void) {
    this.questionCallbacks.push(callback);
    return () => {
      this.unsubscribeFromQuestions(callback);
    };
  }

  unsubscribeFromQuestions(callback: (message: MessageData) => void) {
    this.questionCallbacks = this.questionCallbacks.filter(
      (cb) => cb !== callback
    );
  }

  subscribeToFilesystemMessages(
    callback: (data: InvalidateFilesystemMessage) => void
  ) {
    this.filesystemCallbacks.push(callback);
    return () => {
      this.unsubscribeFromFilesystemMessages(callback);
    };
  }

  unsubscribeFromFilesystemMessages(
    callback: (data: InvalidateFilesystemMessage) => void
  ) {
    this.filesystemCallbacks = this.filesystemCallbacks.filter(
      (cb) => cb !== callback
    );
  }

  subscribeToSystemMessages(callback: (message: SystemMessage) => void) {
    this.systemCallbacks.push(callback);
    return () => {
      this.unsubscribeFromSystemMessages(callback);
    };
  }

  unsubscribeFromSystemMessages(callback: (message: SystemMessage) => void) {
    this.systemCallbacks = this.systemCallbacks.filter((cb) => cb !== callback);
  }

  sendMessage(sessionId: string, message: string) {
    this.socket?.emit('message', { session_id: sessionId, message });
  }

  sendReply(sessionId: string, questionId: string, answer: string) {
    this.socket?.emit('reply', {
      session_id: sessionId,
      in_response_to: questionId,
      answer,
    });
  }
}

export const webSocketClient = new WebSocketClient();
