import { FC, ReactNode, useState, useEffect } from 'react';
import '../styles/FileExplorer.css';
import folderIcon from '../assets/folderIcon.svg';
import angleSmallDown from '../assets/angle-small-down.svg';
import fi from '../assets/fileIcon.svg';
import AngleSmallClosed from '../assets/angle-small-closed.svg';
import FileClosedIcon from '../assets/FileClosedIcon.svg';
import { useSession } from '../contexts/SessionContext';
import { BASE_URL } from '../config/config';
import { useProject } from '../contexts/ProjectContext';
import { normalizePath } from '../utils/fileUtils';
// import { FolderData, FileElement, DirectoryElement } from '../models/Project';

const INDENT_WIDTH = 4;

interface StyledComponentProps {
  children: ReactNode;
  expanded?: boolean;
  depth?: number;
  selected?: boolean;
  glowing?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const EntryText: FC<StyledComponentProps> = ({
  children,
  expanded = false,
  depth = 0,
  selected = false,
  glowing = false,
  className = '',
}) => {
  // const indent = `${depth * INDENT_WIDTH}px`;
  return (
    <div
      className={`entry-text ${expanded ? 'expanded' : ''} ${selected ? 'selected' : ''} ${glowing ? 'glowing' : ''} ${className}`}
      // style={{ marginLeft: indent }}
      style={{ '--indent-level': depth } as React.CSSProperties}
    >
      {children}
    </div>
  );
};

const FolderBox: FC<StyledComponentProps> = ({
  children,
  expanded = false,
  depth = 0,
  selected = false,
  glowing = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = null,
  className = '',
}) => {
  // const indent = `${depth * INDENT_WIDTH}px`;
  return (
    <div
      className={`folder-box ${expanded ? 'expanded' : ''} ${selected ? 'selected' : ''} ${glowing ? 'glowing' : ''} ${className}`}
      // style={{ marginLeft: indent }}
      style={{ '--indent-level': depth } as React.CSSProperties}
      {...(onClick ? { onClick } : {})}
    >
      {children}
    </div>
  );
};

interface FileExplorerProps {
  folderData?: any;
  rootName?: string;
  rootParent?: string;
  depth?: number;
  onSelect?: (selectedPath: string) => void;
  selectedPath?: string;
  setSelectedPath?: (selectedPath: string) => void;
}

const FileExplorer: FC<FileExplorerProps> = ({
  folderData: injectedFolderData,
  depth = 0,
  rootName: injectedRootName,
  rootParent: injectedRootParent,
  onSelect,
  selectedPath: injectedSelectedPath,
  setSelectedPath: injectedSetSelectedPath,
}) => {
  const [expanded, setExpanded] = useState(false);
  const session = useSession();
  const apiKey = session.apiKey;
  const sessionId = session.sessionId;
  const {
    folderData: rootFolderData,
    selectedFilePath,
    setSelectedFilePath,
    projectId,
    setShowEditorData,
    filesWritten,
    getFile,
  } = useProject();

  const rootParent = injectedRootParent ?? '';
  const rootName = injectedRootName ?? '';
  const folderData =
    rootFolderData === null ? {} : (injectedFolderData ?? rootFolderData);
  const selectedPath = injectedSelectedPath ?? selectedFilePath ?? '';
  const setSelectedPath = injectedSetSelectedPath ?? setSelectedFilePath;
  const isSelected = (path: string) =>
    selectedPath === path ? 'selected' : '';

  const isFileGlowing = (filePath: string) => {
    filesWritten?.forEach((writtenFile) => {
      console.debug(
        `isFileGlowing: ${writtenFile} vs ${filePath} = ${normalizePath(writtenFile) === normalizePath(filePath)}`
      );
    });
    return filesWritten?.some(
      (writtenFile) => normalizePath(writtenFile) === normalizePath(filePath)
    );
  };

  useEffect(() => {
    if (!selectedFilePath) return;
    const normalizedSelectedFilePath = normalizePath(selectedFilePath);
    const shouldReload = filesWritten?.some(
      (writtenFile) => normalizePath(writtenFile) === normalizedSelectedFilePath
    );

    if (selectedFilePath && shouldReload) {
      console.log(
        `Fetching updated content for ${normalizePath(selectedFilePath)}`
      );
      getFile(normalizePath(selectedFilePath));
    }
  }, [filesWritten, selectedFilePath, getFile]);

  const handleSeletedFile = async (e: any, path: string) => {
    const substringPath = path.substring(1);
    setSelectedFilePath!(path);

    if (!apiKey || !sessionId || !projectId) {
      console.warn(
        'In handleSeletedFile: API Key or Session Id or Project Id not found'
      );
      return;
    }
    try {
      const response = await fetch(
        `${BASE_URL}/project/${projectId}/${substringPath}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey,
            'X-Session-ID': sessionId,
          },
        }
      );
      const data = await response.text();
      setShowEditorData!(data);
    } catch (error) {
      throw new Error(
        `Error in FileExplorer.tsx while fetching file code, ${error}`
      );
    }
  };

  const handleSelect = (path: string) => {
    const normalizedPath = normalizePath(path);
    if (setSelectedPath) {
      setSelectedPath(path);
    }
    if (setSelectedFilePath) {
      setSelectedFilePath(normalizedPath);
    }
  };

  const traverseObject = (data: any, name: string, rootParent: string) => {
    const sanitizedRootParent = rootParent.replace(/^\/+/, '');
    const sanitizedName = name.startsWith('/') ? name.slice(1) : name;
    if (Array.isArray(data)) {
      return data.map((item: any, index: number) => {
        if (typeof item === 'object' && item !== null) {
          return (
            <div
              key={index}
              className="file-container ${selectedPath === path ? 'selected' : ''}"
              style={{ marginLeft: `${depth * INDENT_WIDTH}px` }}
            >
              <FileExplorer
                folderData={item}
                rootParent={sanitizedRootParent}
                rootName={sanitizedName}
                depth={depth + 1}
                onSelect={handleSelect}
                setSelectedPath={setSelectedPath}
                selectedPath={selectedPath}
              />
            </div>
          );
        } else {
          const path = `${rootParent}/${name}/${item}`;
          return (
            <div
              key={index}
              className={`file-container ${isSelected(path)} ${isFileGlowing(path) ? 'glowing' : ''}`}
              style={{ marginLeft: `${depth * INDENT_WIDTH}px` }} // Was depth * 2.5 px
            >
              <FolderBox
                className="files"
                onClick={(e) => {
                  handleSelect(path);
                  handleSeletedFile(e, path);
                }}
                selected={selectedPath === path}
              >
                <img src={selectedPath === path ? fi : FileClosedIcon} alt="" />
                <EntryText selected={selectedPath === path}>{item}</EntryText>
              </FolderBox>
            </div>
          );
        }
      });
    } else if (typeof data === 'object' && data !== null) {
      return Object.entries(data).map(([key, value], index) => {
        const path = `${rootParent}/${name}/${key}`;
        return (
          <div
            data-testid="file-explorer"
            key={index}
            style={{
              marginLeft: `${depth * INDENT_WIDTH}px`,
              color: 'white',
            }}
            className={`folder-container ${isSelected(path)}`}
          >
            <FolderBox
              onClick={() => {
                setExpanded(!expanded);
                handleSelect(path);
              }}
              className={`folder-box ${expanded ? 'expanded' : ''} ${isFileGlowing(path) ? 'glowing' : ''}`}
              selected={selectedPath === path}
            >
              <div className={`folder-element ${isSelected(path)}`}>
                <span className="spacer" />
                <span className="disclosure-icon">
                  <img
                    src={expanded ? angleSmallDown : AngleSmallClosed}
                    alt={expanded ? 'v' : '&gt;'}
                  />
                </span>
                <img src={folderIcon} alt="" />
                <EntryText selected={selectedPath === path}>{key}</EntryText>
              </div>
            </FolderBox>
            {expanded && (
              <FileExplorer
                folderData={value}
                rootParent={rootParent + '/' + name}
                rootName={key}
                depth={depth + 1}
                onSelect={handleSelect}
                setSelectedPath={setSelectedPath}
                selectedPath={selectedPath}
              />
            )}
          </div>
        );
      });
    } else {
      return (
        <div
          style={{ marginLeft: `${depth * INDENT_WIDTH}px`, color: 'white' }}
        >
          {data}
        </div>
      );
    }
  };

  return (
    <div data-testid="file-explorer">
      {traverseObject(folderData, rootName as string, rootParent || '')}
    </div>
  );
};

export default FileExplorer;
