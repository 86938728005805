import React from 'react';
import '../../styles/LandingPage.css';
// import { CardContainer } from "./CardContainer";
// import BlogImage from "../../assets/BlogImage.svg";
// import { HeaderContainer } from "./HeaderContainer";
// import { DeveloperSection } from "./DeveloperSection";
// import { FooterSection } from "./FooterSection";
import Desktop from '../../screens/Desktop/Desktop';
import '../../screens/Desktop/style.css';

const LandingPageMain: React.FC = () => {
  return (
    <Desktop />
    // <div className="Landing_Page_Main" data-testid="landing-page-main">
    //   <HeaderContainer />
    //   <CardContainer />
    //   <DeveloperSection />
    //   {/* <SubscribeSection /> */}
    //   <FooterSection />
    // </div>
  );
};

export default LandingPageMain;

// const SubscribeSection = () => {
//   return (
//     <div className="Subscription_Section_Container">
//       <div className="Subscription_Section_Box1">
//         <h1>
//           Accelerate your dev flow <span>now</span>
//         </h1>
//       </div>
//       <div className="Subscription_Section_Box2">
//         <div className="Subscription_Section_Box2_Free">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Free_Title">Free</h4>
//             <h1>
//               <sup>$</sup>0
//             </h1>
//             <button className="Subscription_Section_Box2_Free_Button">
//               <b>GET STARTED</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//         <div className="Subscription_Section_Box2_Priceline">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Priceline_Title">
//               Priceline
//             </h4>
//             <h1>
//               <sup>$</sup>10
//             </h1>
//             <button className="Subscription_Section_Box2_Priceline_Button">
//               <b>TRY FOR FREE</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//         <div className="Subscription_Section_Box2_Enterprise">
//           <div className="Subscription_Section_Box2_Free_Content">
//             <h4 className="Subscription_Section_Box2_Enterprise_Title">
//               Enterprise
//             </h4>
//             <h1>
//               <sup>$</sup>40
//             </h1>
//             <button className="Subscription_Section_Box2_Enterprise_Button">
//               <b>TRY FOR FREE</b>
//             </button>
//             <ul>
//               <li>Unlimited users</li>
//               <li>Unlimited objects</li>
//               <li>2 environments</li>
//               <li>3 days of activity history</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
