import React, { useState } from 'react';
import '../styles/PopupMenu.css';

export interface PopupMenuProps {
  id: string;
  name: string;
  items: PopupMenuItem[];
  className?: string;
  onChange?: (selected: [string, string]) => void; // Tuple of key and label
}

export interface PopupMenuItem {
  key: string;
  label: string;
}

const PopupMenu: React.FC<PopupMenuProps> = ({
  id,
  name,
  items,
  className,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PopupMenuItem | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: PopupMenuItem) => {
    setSelectedItem(item);
    setIsOpen(false); // Close menu after selection

    if (onChange) {
      onChange([item.key, item.label]); // Pass tuple [key, label] to the parent component
    }
  };

  return (
    <div className={`popup-menu ${className}`}>
      <div id={id} className="dropdown" onClick={toggleMenu}>
        <div className="selected-item">
          {selectedItem ? selectedItem.label : name || 'Select an option'}
        </div>
        {isOpen && (
          <ul className="menu-list">
            {items.map((item) => (
              <li
                key={item.key}
                className="menu-item"
                onClick={() => handleItemClick(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PopupMenu;
