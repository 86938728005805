import React, { useEffect } from 'react';
import './App.css';
import Main from './components/Main';
import Header from './components/Header';
import { SessionProvider } from './contexts/SessionContext';
import { ConnectionProvider } from './contexts/ConnectionContext';
import { ProjectProvider, useProject } from './contexts/ProjectContext';
import { MessageProvider } from './contexts/MessageContext';
import { VerbProvider } from './contexts/VerbContext';
import { ShortcutProvider } from './contexts/ShortcutContext';
import { ActivityProvider } from './contexts/ActivityContext';

import MessageControllerInitializer from './controllers/MessageControllerInitializer';

function App() {
  const { projectId, projectName } = useProject();

  useEffect(() => {
    const updateTitle = () => {
      document.title = projectId ? `Epicenter | ${projectName}` : 'Epicenter';
    };

    updateTitle();
  }, [projectId, projectName]);

  return (
    <ShortcutProvider>
      <SessionProvider>
        <ConnectionProvider>
          <ActivityProvider>
            <ProjectProvider>
              <MessageProvider>
                <MessageControllerInitializer />
                <VerbProvider>
                  <Header />
                  <Main />
                  {/* <Footer /> */}
                </VerbProvider>
              </MessageProvider>
            </ProjectProvider>
          </ActivityProvider>
        </ConnectionProvider>
      </SessionProvider>
    </ShortcutProvider>
  );
}

export default App;
