import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Menu, MenuItem, MenuListProps, styled } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  MenuElement,
  MenuItemData,
  MenuData,
  MenuSeparator,
} from '../models/MenuData';
import { useShortcutContext } from '../contexts/ShortcutContext';
import { formatShortcut } from '../utils/menuUtils';
import '../styles/Menu.css';

interface CommonMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  MenuListProps?: MenuListProps;
  items: MenuElement[];
}

// Styled components for menu layout
const CustomMenu = styled(Menu)({});
const CustomMenuItem = styled(MenuItem)({});

// Type guards for distinguishing menu elements
function isMenuSeparator(item: MenuElement): item is MenuSeparator {
  return (item as MenuSeparator).separator === true;
}

function isMenuData(item: MenuElement): item is MenuData {
  return (item as MenuData).items !== undefined;
}

function isMenuItemData(item: MenuElement): item is MenuItemData {
  return (item as MenuItemData).label !== undefined;
}

// Store close functions in a list for ordered closing
let closeFunctions: (() => void)[] = [];

// Submenu component to handle nested menus
const SubmenuComponent: React.FC<{
  item: MenuData;
  onCloseAll: () => void;
}> = ({ item, onCloseAll }) => {
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  // Add the close function to the list when the submenu opens
  if (submenuAnchorEl) {
    closeFunctions.push(handleSubmenuClose);
  }

  return (
    <>
      <CustomMenuItem
        className="menu-item"
        onMouseEnter={handleSubmenuOpen}
        aria-haspopup="true"
      >
        {item.label}
        <ArrowRightIcon style={{ marginLeft: 'auto' }} />
      </CustomMenuItem>
      <CustomMenu
        anchorEl={submenuAnchorEl}
        open={Boolean(submenuAnchorEl)}
        onClose={handleSubmenuClose}
        className="submenu"
        MenuListProps={{
          onMouseLeave: onCloseAll,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderMenuItems(item.items, onCloseAll)}
      </CustomMenu>
    </>
  );
};

// Function to render menu items, with submenu handling and shortcut display
const renderMenuItems = (items: MenuElement[], onCloseAll: () => void) => {
  return items.map((item, index) => {
    const uniqueKey = `menu-item-${index}`;

    if (isMenuSeparator(item)) {
      return <Divider className="menu-divider" key={uniqueKey} />;
    } else if (isMenuData(item)) {
      return (
        <SubmenuComponent key={uniqueKey} item={item} onCloseAll={onCloseAll} />
      );
    } else if (isMenuItemData(item)) {
      return (
        <CustomMenuItem
          key={uniqueKey}
          onClick={() => {
            item.onSelect?.();
            onCloseAll();
          }}
          disabled={item.enabled === false}
          className="menu-item"
        >
          <span>{item.label}</span>
          {item.shortcut && (
            <>
              <span className="shortcut-spacer" />
              <span className="shortcut">{formatShortcut(item.shortcut)}</span>
            </>
          )}
        </CustomMenuItem>
      );
    }
    return null;
  });
};

// Main GenericMenu component with reverse closing order and shortcut handling
const GenericMenu: React.FC<CommonMenuProps> = ({
  anchorEl,
  open,
  onClose,
  MenuListProps,
  items,
}) => {
  const { registerShortcut, unregisterShortcut } = useShortcutContext();

  // Create handleCloseAll with useCallback to ensure it’s stable
  const handleCloseAll = useCallback(() => {
    // Close each submenu in reverse order
    for (let i = closeFunctions.length - 1; i >= 0; i--) {
      closeFunctions[i]();
    }
    // Clear the close functions list and close the main menu
    closeFunctions = [];
    onClose();
  }, [onClose]);

  // Register shortcuts on mount and clean up on unmount
  useEffect(() => {
    items.forEach((item) => {
      if (isMenuItemData(item) && item.shortcut) {
        registerShortcut({
          keys: item.shortcut,
          action: () => {
            item.onSelect?.();
            handleCloseAll();
          },
        });
      }
    });

    // Cleanup shortcuts on unmount
    return () => {
      items.forEach((item) => {
        if (isMenuItemData(item) && item.shortcut) {
          unregisterShortcut(item.shortcut);
        }
      });
    };
  }, [items, registerShortcut, unregisterShortcut, handleCloseAll]);

  return (
    <CustomMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseAll}
      MenuListProps={MenuListProps}
    >
      {renderMenuItems(items, handleCloseAll)}
    </CustomMenu>
  );
};

export default GenericMenu;
