import React from 'react';
import { Button } from '@mui/material';
import GenericMenu from '../generic components/MenuGeneric';
import { useProject } from '../contexts/ProjectContext';
import { MenuElement } from '../models/MenuData';

interface ProjectSelectionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

const ProjectSelectionMenu: React.FC<ProjectSelectionMenuProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const { setProjectId, projects } = useProject();

  const projectItems: MenuElement[] = projects.map((project) => ({
    key: project.id,
    label: project.human_readable_name || project.dir_name,
    onSelect: () => {
      setProjectId(project.id);
    },
  }));

  return (
    <GenericMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      items={projectItems}
    />
  );
};

export default ProjectSelectionMenu;
