import React, { useEffect, useRef } from 'react';
import Message from './Message';
import { useMessages } from '../../contexts/MessageContext';
import './ChatBox.css';

const MessageList: React.FC = () => {
  const { messages } = useMessages();
  const endOfMessagesRef = useRef<HTMLDivElement>(null); // Ref for the dummy div at the end

  useEffect(() => {
    // Scroll to the dummy div whenever messages change
    if (
      endOfMessagesRef.current &&
      typeof endOfMessagesRef.current.scrollIntoView === 'function'
    ) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="message-list">
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      {/* Dummy div to scroll to */}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
