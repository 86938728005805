import React from 'react';
import PulsingIcon from './PulsingIcon';
import { ReactComponent as BrainSVG } from '../assets/brain.svg';
import '../styles/ActivityIndicator.css';
import { useActivity } from '../contexts/ActivityContext';

const ActivityIndicator: React.FC = ({}) => {
  const { active } = useActivity();
  const color = active ? '#4453D8' : '#5A6181'; // Active and idle colors

  return (
    <div className="activity-indicator">
      <div className="icon-wrapper">
        <PulsingIcon
          svg={<BrainSVG />}
          size={32}
          color={color}
          active={active} // Control animation and color with active state
        />
      </div>
      <span className="activity-text">{active ? 'Working' : 'Idle'}</span>
    </div>
  );
};

export default ActivityIndicator;
