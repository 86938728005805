// CustomButton.tsx
import '../styles/Button.css';
import React, { ReactNode, MouseEvent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      {...props}
      variant="contained"
      className="secondaryButton custom-button"
      sx={{ width: '5.5rem' }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
