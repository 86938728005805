import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  CircularProgress,
  DialogActions,
  Slide,
} from '@mui/material';
import '../styles/Dialog.css';
import CustomizedSnackbars from './ErrorAlert';
import { TransitionProps } from '@mui/material/transitions';
import SecondaryButton from './SecondaryButton';
import PrimaryButton from './PrimaryButton';
import PopupMenu, { PopupMenuItem } from './PopupMenu';

interface ProjectConfigItem {
  description: string;
  label: string;
  uiType?: string;
  required: boolean;
  type: string;
  display?: boolean;
  from_context?: boolean;
}

interface ProjectConfig {
  [key: string]: ProjectConfigItem;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogGenericProps {
  displayName: string;
  handleClose: () => void;
  handleSubmit: (e: any) => void;
  handleUpdate: (e: any) => void;
  loading: boolean;
  errorPost: boolean;
  open: boolean;
  dialogFields: ProjectConfig;
  value?: string;
  passToDialog?: (open: boolean) => void;
  validationErrors: { [key: string]: string };
  projectName?: string | null;
  submitLoading?: boolean;
}

function DialogGeneric(props: DialogGenericProps) {
  const open = Boolean(props.open);

  // State to store the selected key from the PopupMenu
  const [selectedKey, setSelectedKey] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (open) {
      console.debug('Dialog opened', props.handleSubmit, props.handleClose);
    } else {
      console.debug('Dialog closed', props.handleSubmit, props.handleClose);
    }
  }, [open, props.handleSubmit, props.handleClose]);

  const handleMenuChange = (key: string) => (selected: [string, string]) => {
    const [selectedKey, selectedLabel] = selected; // Destructure the tuple
    setSelectedKey((prev) => ({
      ...prev,
      [key]: selectedKey, // Store the key
    }));
    console.log(`Selected key: ${selectedKey}, label: ${selectedLabel}`);
  };

  // Form submit handler to log FormData before calling props.handleSubmit
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission

    const formData = new FormData(e.currentTarget); // Capture the form data

    // Log form data for debugging
    console.debug('FormData Contents:');
    formData.forEach((value, key) => {
      console.debug(`${key}: ${value}`);
    });

    console.debug('\n\n*** Form submitted ***\n');
    // Call the actual handleSubmit provided via props
    props.handleSubmit(e);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBox"
        data-testid="dialog"
      >
        <Paper className="dialogPaper">
          <DialogTitle className="dialogTitle">{props.displayName}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
            <div>
              <form onSubmit={onFormSubmit}>
                {props.loading ? (
                  <Box className="centerBox">
                    <CircularProgress />
                  </Box>
                ) : (
                  Object.keys(props.dialogFields).map((key: string) => {
                    const property: ProjectConfigItem = props.dialogFields[key];
                    console.debug(
                      `Handling ${property.type} type property ${key}: `,
                      property
                    );
                    if (property?.display === false && key === 'project_id') {
                      return <input type="hidden" name={key} key={key} />;
                    } else if (property.uiType === 'list') {
                      const menuItems: PopupMenuItem[] = [
                        { key: '', label: 'None' },
                        { key: 'node', label: 'Node.js' },
                        { key: 'ts-node', label: 'Node with TypeScript' },
                        { key: 'swift', label: 'Swift' },
                        { key: 'python', label: 'Python 3.x' },
                      ];
                      return (
                        <div key={key} className="margin inputContainer">
                          <label
                            htmlFor={key}
                            className={`formLabel ${property.required ? 'required' : ''}`}
                          >
                            {property.label}
                            {property.required && (
                              <span className="requiredField">*</span>
                            )}
                            <PopupMenu
                              items={menuItems}
                              id={key}
                              className={
                                property?.display === false
                                  ? 'hidden'
                                  : 'formField'
                              }
                              name={key}
                              onChange={handleMenuChange(key)}
                            />
                            {/* Hidden input to capture the selected key in the form */}
                            <input
                              type="hidden"
                              name={key}
                              value={selectedKey[key] || ''}
                            />
                          </label>
                        </div>
                      );
                    } else
                      return (
                        <div key={key} className="margin inputContainer">
                          <label
                            htmlFor={key}
                            className={`formLabel ${property.required ? 'required' : ''}`}
                          >
                            {property.label}
                            {property.required && (
                              <span className="requiredField">*</span>
                            )}
                            <TextField
                              margin="dense"
                              id={key}
                              className={
                                'formField ' +
                                (property?.display === false
                                  ? 'hidden'
                                  : 'formField')
                              }
                              placeholder={`Type ${property.label} Here`}
                              type={key === 'password' ? 'password' : 'text'}
                              name={key}
                              error={!!props.validationErrors[key]}
                              helperText={props.validationErrors[key]}
                              fullWidth
                              onChange={props.handleUpdate}
                              autoComplete="off"
                              multiline={property.uiType === 'textarea'}
                              rows={property.uiType === 'textarea' ? 4 : 1}
                            />
                          </label>
                        </div>
                      );
                  })
                )}
                <DialogActions>
                  <SecondaryButton onClick={props.handleClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton type="submit">
                    {props.submitLoading ? (
                      <CircularProgress size={25} />
                    ) : (
                      'Submit'
                    )}
                  </PrimaryButton>
                </DialogActions>
              </form>
            </div>
          </DialogContent>
        </Paper>
      </Dialog>
      {props.errorPost && <CustomizedSnackbars />}
    </React.Fragment>
  );
}

export default DialogGeneric;
