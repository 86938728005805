import React, { useState, useCallback, useMemo } from 'react';
import GenericMenu from '../generic components/MenuGeneric';
import { MenuElement, MenuSeparator } from '../models/MenuData';
import { useVerb } from '../contexts/VerbContext';
import { useProject } from '../contexts/ProjectContext';
import DialogBox from './DialogBox';
import { Verb } from '../models/VerbDescriptor';

interface VerbMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

const VerbMenu: React.FC<VerbMenuProps> = ({ anchorEl, open, onClose }) => {
  const { allVerbs } = useVerb();
  const { projectId } = useProject();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');

  // Handle selection of a verb item from the menu
  const handleVerbSelect = useCallback(
    (verb: Verb) => {
      setValue(verb.name); // Set the verb name
      setDisplayName(verb.display_name); // Set the display name
      setDialogOpen(true); // Open the dialog
      onClose(); // Close the menu
    },
    [onClose]
  );

  // Close the dialog box
  const handleDialogClose = useCallback((isOpen: boolean) => {
    setDialogOpen(isOpen);
  }, []);

  // Create grouped and disabled menu items from verbs
  const menuItems: MenuElement[] = useMemo(() => {
    // console.debug(`Rendering verb menu. projectId = ${projectId}`);
    const createMenuItem = (verb: Verb) => {
      const isEnabled = !(verb.projectRequired && !projectId);
      // console.debug(
      //   `Menu item ${verb.name} is ${isEnabled ? 'enabled' : 'disabeld'}`
      // );
      return {
        key: verb.name,
        label: verb.display_name,
        onSelect: isEnabled ? () => handleVerbSelect(verb) : undefined, // Assign onSelect only if enabled
        enabled: isEnabled,
      };
    };

    const featuredVerbs = allVerbs
      .filter((verb) => verb.featured)
      .map(createMenuItem);
    const nonFeaturedVerbs = allVerbs
      .filter((verb) => !verb.featured)
      .map(createMenuItem);

    return [
      ...featuredVerbs,
      { separator: true } as MenuSeparator,
      ...nonFeaturedVerbs,
    ];
  }, [allVerbs, projectId, handleVerbSelect]);

  return (
    <>
      <GenericMenu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        items={menuItems}
      />
      {dialogOpen && (
        <DialogBox
          boxOpen={dialogOpen}
          callFromDialog={handleDialogClose}
          value={value}
          displayName={displayName}
        />
      )}
    </>
  );
};

export default VerbMenu;
