import React, { useMemo } from 'react';
import GenericMenu from '../generic components/MenuGeneric';
import { MenuElement, MenuSeparator } from '../models/MenuData';
import { useProject } from '../contexts/ProjectContext';

interface ProjectMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

const ProjectMenu: React.FC<ProjectMenuProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const {
    selectedFilePath,
    getFile,
    saveFile,
    deleteFile,
    deleteProject,
    projectId,
  } = useProject();

  // Handlers with proper closure
  const handleRevertFile = React.useCallback(async () => {
    if (!selectedFilePath) {
      console.warn('No file selected to revert.');
      return;
    }
    try {
      await getFile(selectedFilePath);
      console.log(`File ${selectedFilePath} reverted.`);
    } catch (error) {
      console.error('Error reverting file:', error);
    }
    onClose();
  }, [selectedFilePath, getFile, onClose]);

  const handleSaveFile = React.useCallback(async () => {
    if (!selectedFilePath) {
      console.warn('No file selected to save.');
      return;
    }
    try {
      const content = '...'; // Replace with actual content from the editor
      await saveFile(selectedFilePath, content);
      console.log(`File ${selectedFilePath} saved.`);
    } catch (error) {
      console.error('Error saving file:', error);
    }
    onClose();
  }, [selectedFilePath, saveFile, onClose]);

  const handleDeleteFile = React.useCallback(async () => {
    if (!selectedFilePath) {
      console.warn('No file selected to delete.');
      return;
    }
    try {
      await deleteFile(selectedFilePath);
      console.log(`File ${selectedFilePath} deleted.`);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
    onClose();
  }, [selectedFilePath, deleteFile, onClose]);

  const handleDeleteProject = React.useCallback(async () => {
    if (!projectId) {
      console.warn('No project selected to delete.');
      return;
    }
    try {
      await deleteProject(projectId);
      console.log(`Project ${projectId} deleted.`);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
    onClose();
  }, [projectId, deleteProject, onClose]);

  const menuItems: (MenuElement | MenuSeparator)[] = useMemo(() => {
    return [
      {
        label: 'Revert File',
        onSelect: handleRevertFile,
        enabled: !!selectedFilePath,
      },
      {
        label: 'Save File',
        onSelect: handleSaveFile,
        enabled: !!selectedFilePath,
      },
      {
        label: 'Delete File',
        onSelect: handleDeleteFile,
        enabled: !!selectedFilePath,
      },
      { separator: true },
      {
        label: 'Delete Project',
        onSelect: handleDeleteProject,
        enabled: !!projectId,
      },
    ];
  }, [
    selectedFilePath,
    projectId,
    handleRevertFile,
    handleSaveFile,
    handleDeleteFile,
    handleDeleteProject,
  ]);

  return (
    <GenericMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      items={menuItems}
    />
  );
};

export default ProjectMenu;
