import React from 'react';
import '../../styles/Login.css';
import { BASE_URL } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import DialogGeneric from '../../generic components/DialogGeneric';
import { useSession } from '../../contexts/SessionContext';

interface ProjectConfigItem {
  description: string;
  label: string;
  required: boolean;
  type: string;
}

interface ProjectConfig {
  [key: string]: ProjectConfigItem;
}

interface LoginProps {
  handleLoginClick?: (show: boolean) => void;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Login(props: LoginProps) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [validationErrors, setValidationErrors] = React.useState<{
    [key: string]: string;
  }>({});
  const [formData, setFormData] = React.useState({});
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { updateCredentials } = useSession();

  const handleClose = () => {
    setOpen(false);
    props.handleLoginClick!(false);
    props.setShow!(false);
    // navigate("/");
  };

  const displayName = 'Login';
  const loginData: ProjectConfig = {
    login: {
      description: 'User Name for login',
      label: 'User Name',
      required: true,
      type: 'string',
    },
    password: {
      description: 'Password for login',
      label: 'Password',
      required: true,
      type: 'string',
    },
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitLoading(true);

    // Validate required fields
    const errors: { [key: string]: string } = {};
    Object.keys(loginData).forEach((key: string) => {
      const property: ProjectConfigItem = loginData[key];
      if (property.required && !formData[key as keyof typeof formData]) {
        errors[key] = `${property.label} is required.`;
      }
    });

    // Update state with validation errors
    setValidationErrors(errors);

    // If there are validation errors, stop submission
    if (Object.keys(errors).length > 0) {
      setSubmitLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      e.target.reset();
      if (!response.ok) {
        throw new Error('Login failed');
      }
      setOpen(false);
      props.handleLoginClick!(false);
      // Assuming your API returns a user object with username and token
      setSubmitLoading(false);
      const apiResponse = (await response.json()) as {
        login: string;
        api_key: string;
        session_id: string;
        username: string;
        email: string;
      };
      sessionStorage.removeItem('userCredentials');
      // sessionStorage.setItem("userCredentials", JSON.stringify(apiResponse));
      updateCredentials({
        apiKey: apiResponse.api_key,
        sessionId: apiResponse.session_id,
        login: apiResponse.login,
        username: apiResponse.username,
        email: apiResponse.email,
      });

      navigate('/app');
    } catch (error) {
      setSubmitLoading(false);
      console.log(`Error creating User ${error}`);
    }
  };

  const handleUpdate = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: '' }); // Clear error message on input change
  };

  return (
    <div data-testid="login-id">
      <DialogGeneric
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        loading={false}
        errorPost={false}
        open={open}
        displayName={displayName}
        dialogFields={loginData}
        submitLoading={submitLoading}
        validationErrors={validationErrors}
      />
    </div>
  );
}
export default Login;
