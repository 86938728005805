import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import FileExplorer from './FileExplorer';
import { useProject } from '../contexts/ProjectContext';
import { useSession } from '../contexts/SessionContext';
import { BASE_URL } from '../config/config';
import SearchIcon from '../assets/searchIcon.svg';
import '../styles/Sidebar.css';

const Sidebar = () => {
  const [projectData, setProjectData] = React.useState<any>();
  const [selectedPath, setSelectedPath] = React.useState<string>('root');

  function onSelect(path: string) {
    setSelectedPath(path);
  }

  const { projectId, isDirty, setDirty } = useProject();
  const sessionData = useSession();
  const apiKey = sessionData.apiKey;
  const sessionId = sessionData.sessionId;

  // Memoize the fetchProjectById function to avoid re-creation on each render
  const fetchProjectById = React.useCallback(async () => {
    if (!projectId || !apiKey || !sessionId) {
      console.warn(
        'In fetchProjectById: Project ID or API Key or Session Id not found'
      );
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/project/${projectId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey,
          'X-Session-ID': sessionId,
        },
      });
      if (!response.ok) {
        console.debug('Check cors error');
        throw new Error(`Error Fetching the project (HTTP ${response.status})`);
      }
      const data = await response.json();
      setProjectData(data);
      console.debug('project data', data);
      setDirty(false); // Clear the dirty flag once the project is loaded
    } catch (error) {
      console.error(`Error Fetching the project by ID, ${error}`);
    }
  }, [projectId, apiKey, sessionId, setDirty]); // Include necessary dependencies here

  React.useEffect(() => {
    if (projectId !== null) {
      console.debug('Fetching project due to projectId change');
      fetchProjectById(); // Fetch project data when projectId changes
    }
  }, [projectId, fetchProjectById]); // Now `fetchProjectById` is included as a dependency

  React.useEffect(() => {
    if (isDirty) {
      console.debug('Fetching project due to dirty state');
      fetchProjectById(); // Fetch project data when dirty state is set
    }
  }, [isDirty, fetchProjectById]); // Now `fetchProjectById` is included as a dependency

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          sx={{
            background: '#363E56',
          }}
        >
          <Box className="Sidebar_Main_Container">
            <Box
              sx={{
                marginTop: '5px',
                alignItems: 'center',
                overflowY: 'hidden',
              }}
            >
              {/* <div className="Explorer_Input">
                <input
                  type="text"
                  className="Input_Box"
                  placeholder="Type here to filter list"
                />
                <img src={SearchIcon} alt="Search button" />
              </div> */}
              <div className="Sidebar_Main_FileExplorer">
                <FileExplorer
                  folderData={projectData && Object.values(projectData['/'])}
                  rootName="."
                  depth={1}
                  onSelect={onSelect}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                />
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Sidebar;
