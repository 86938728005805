import React, { useState } from 'react';
import AIBot from '../../../assets/AIBot.svg';
import { Divider, Paper } from '@mui/material';
import SmallAngleDown from '../../../assets/angle-small-down.svg';
import { useVerb } from '../../../contexts/VerbContext';
import { useProject } from '../../../contexts/ProjectContext';
import FeaturedVerbs from './FeaturedVerbs';
import MonacoEditor from '../monacoEditor/MonacoEditor';
import '../../../styles/AICommands.css';

function AICommands() {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { featuredVerbs } = useVerb();
  const { projectId } = useProject();

  // Toggle VerbMenu display
  const handleCommandDown = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <div className="AICommands_Main_Container" data-testid="AICommands-comp">
      <Paper>
        <div className="AICommands_Main_Box1">
          <div className="AICommands_Main_Box1_Content">
            <img src={AIBot} alt="AIBot" className="AIBot_Icon" />
            <p>| What would you like me to do for you?</p>
          </div>
          <Divider sx={{ background: '#A0A8C4' }} />
          <div className="AICommands_Main_Box1_Toggle">
            {/* Preserve FeaturedVerbs section */}
            {featuredVerbs.length > 0 && (
              <FeaturedVerbs
                featuredVerb={featuredVerbs}
                projectId={projectId}
              />
            )}
          </div>
        </div>
      </Paper>

      <div className="AICommands_Content_Container">
        <div className="MonacoEditor_Component">
          <MonacoEditor />
        </div>
      </div>
    </div>
  );
}

export default AICommands;
