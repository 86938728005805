export const formatShortcut = (shortcut: string[]): string => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const joiner = isMac ? '' : ' + ';
  return shortcut
    .map((key) => {
      switch (key.toLowerCase()) {
        case 'ctrl':
          return isMac ? '⌘' : 'Ctrl';
        case 'alt':
          return isMac ? '⌥' : 'Alt';
        case 'shift':
          return '⇧';
        case 'cmd':
        case 'command':
          return '⌘';
        default:
          return key.toUpperCase();
      }
    })
    .join(joiner);
};
