import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { MessageData } from './Message';
import './ChatBox.css';
import { useSession } from '../../contexts/SessionContext';
import ConnectionState from '../ConnectionState';
import { webSocketClient } from '../../ws/WebSocketClient';
import Ask, { AskProps } from '../Ask';
import ActivityIndicator from '../ActivityIndicator';
// import { SystemMessage } from '../../ws/messages/SystemMessage';
import { useMessages } from '../../contexts/MessageContext';
import ActivitySoundPlayer from '../ActivitySoundPlayer';

const ChatBox: React.FC = () => {
  const { apiKey, sessionId } = useSession();
  const { addMessage } = useMessages();

  // State for controlling Ask dialog visibility and its content
  const [showAsk, setShowAsk] = useState(false);
  const [askData, setAskData] = useState<AskProps | null>(null);

  const handleMessage = React.useCallback(
    (data: MessageData) => {
      addMessage(data.message, data.sender, data.id);
    },
    [addMessage]
  );

  useEffect(() => {
    const handleQuestion = (data: MessageData) => {
      console.log('Received question:', data);

      setAskData({
        questionId: data.id,
        question: data.message,
        agent: data.sender,
        handleClick: setShowAsk,
        setShow: setShowAsk,
        handleMessage,
      });
      setShowAsk(true);
    };

    webSocketClient.subscribeToQuestions(handleQuestion);
    return () => {
      webSocketClient.unsubscribeFromQuestions(handleQuestion);
    };
  }, [apiKey, sessionId, addMessage, handleMessage]);

  return (
    <div className="chat-box-container">
      <div className="status-panel">
        <ConnectionState />
        <ActivityIndicator />
        <ActivitySoundPlayer />
      </div>
      <div className="message-list-container">
        <MessageList />
      </div>
      <div className="message-input-container">
        <MessageInput />
      </div>
      {/* Only render Ask dialog when showAsk is true */}
      {showAsk && askData && (
        <Ask
          questionId={askData.questionId}
          question={askData.question}
          agent={askData.agent}
          handleClick={askData.handleClick}
          setShow={askData.setShow}
          handleMessage={handleMessage} // Pass handleMessage to Ask
        />
      )}
    </div>
  );
};

export default ChatBox;
