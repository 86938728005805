import React from 'react';
import '../styles/PulsingIcon.css';

interface PulsingIconProps {
  svg: React.ReactElement;
  size?: number;
  color?: string;
  active?: boolean;
}

const PulsingIcon: React.FC<PulsingIconProps> = ({
  svg,
  size = 40,
  color = '#ffffff',
  active = true,
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        color: color,
      }}
      className={`pulsing-icon ${active ? 'active' : 'idle'}`}
    >
      {React.cloneElement(svg, {
        width: size,
        height: size,
        fill: 'currentColor', // Ensures color is applied through 'currentColor'
      })}
    </div>
  );
};

export default PulsingIcon;
